import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./slices/user";
import { appSlice } from "./slices/app";
import { tempSlice } from "./slices/temp";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "rootUser",
  storage,
  whitelist: ["user", "app"],
};

const reducer = combineReducers({
  user: userSlice.reducer,
  app: appSlice.reducer,
  // client: clientSlice.reducer,
  temp: tempSlice.reducer,
  // aplicacion: aplicacionSlice.reducer,
  // cliente: clienteSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
