import React from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "./Sidebar.css";

import { useSelector } from "react-redux";

// import PublicElements from "./sidebar/publicElements";
import PrivateElements from "./sidebar/privateElements";

const SidebarMenu = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  // const [selected, setSelected] = useState("Clientes");
  const user = useSelector((state) => state.user);
  // const { currentPage } = useSelector((state) => state.app);

  // const changePage = async (page) => {
  //     await dispatch(setCurrentPage(page));
  // }

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        borderColor: "blue !important",
      }}
    >
      {broken && (
        <IconButton
          onClick={() => {
            toggleSidebar();
            collapseSidebar(false);
          }}
          className={"btn-float-2"}
        >
          <MenuOutlinedIcon />
        </IconButton>
      )}
      <Sidebar
        breakPoint="sm"
        backgroundColor={colors.primary[400]}
        width={"200px"}
        defaultCollapsed={true}
        style={{ flex: 1, backgroundColor: "green", height: "100%" }}
      >
        <Menu
          menuItemStyles={{
            button: () => {
              return {
                "&:hover": {
                  backgroundColor: "rgba(76, 175, 80, 0.0)",
                },
              };
            },
          }}
        >
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => collapseSidebar()}
            icon={
              collapsed ? (
                <KeyboardDoubleArrowRightIcon />
              ) : (
                <KeyboardDoubleArrowLeftIcon />
              )
            }
            style={{ margin: "10px 0 20px 0", color: colors.grey[100] }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              ml="15px"
            >
              <Typography
                variant="h4"
                color={colors.grey[100]}
                className={"pro-sidebar"}
              >
                Menú
              </Typography>
            </Box>
          </MenuItem>
        </Menu>

        {/* USER */}
        {!collapsed && user.isLogged && (
          <Box mb="25px">
            <Box display="flex" justifyContent={"center"} alignItems="center">
              <img
                alt="profile-user"
                width={"80px"}
                height="80px"
                src={`https://ranca.cangurodigital.cl/files/avatar/${user.loginUser.avatar}`}
                // uri: `https://ranca.cangurodigital.cl/files/avatar/${user.avatar}`,
                // src="https://www.w3schools.com/howto/img_avatar.png"
                style={{ cursor: "pointer", borderRadius: "50%" }}
              />
            </Box>

            <Box textAlign={"center"}>
              <Typography
                variant="h3"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px 0 0 0" }}
              >
                {user.loginUser.name}
              </Typography>
              <Typography variant="h6" color={colors.greenAccent[500]}>
                Administrador
              </Typography>
            </Box>
          </Box>
        )}

        {/* MENU ITEM */}
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              return {
                color: active ? "#6870fa" : colors.grey[100],
                "&:hover": {
                  backgroundColor: "rgba(76, 175, 80, 0.0)",
                  color: "#868dfb !important",
                },
              };
            },
          }}
        >
          {
            // user.isLogged && !user.isPublicUser &&
            user.isLogged && !user.isPublicUser && (
              <PrivateElements collapsed={collapsed} />
            )
          }

          {/* <PublicElements collapsed={ collapsed }/> */}
        </Menu>
      </Sidebar>
    </div>
  );
};

export default SidebarMenu;
