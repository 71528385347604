import Header from "../../globalComponents/Header";
import { Box } from "@mui/material";
import Image from "mui-image";
import logo_main from "../../assets/image/logo_main.png";

// import { useSelector, useDispatch } from "react-redux";
// import { useEffect } from "react";

const Dashboard = () => {
  // const user = useSelector((state) => state.user);
  // const dispatch = useDispatch();

  //   useEffect(() => {
  //     console.log("DASHBOARD LOGIN", user);
  //   });

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={"Dashboard"} subtitle="Bienvenido a tu dashboard" />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "", height: "70vh" }}
      >
        <Image
          src={logo_main}
          duration={0}
          height={350}
          width={350}
          fit={"contain"}
          style={{ marginBottom: 100, backgroundColor: "" }}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
