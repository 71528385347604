import PrivateRouter from "./router/PrivateRouter";
import PublicRouter from "./router/PublicRouter";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

function App() {
  const user = useSelector((state) => state.user);

  return (
    <>
      {/*Login de usuario*/}
      {/* {!user.isLogged && !user.isPublicUser && <Login />} */}
      {!user.isLogged && <PublicRouter />}

      {/*Sólo para usuarios logueados*/}
      {user.isLogged && <PrivateRouter />}

      <Backdrop
        open={false}
        style={{
          position: "absolute",
          color: "red",
          backgroundColor: "rgba(240,230,0,0.4)",
          zIndex: "drawer",
        }}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}

export default App;
