import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useSelector, useDispatch} from "react-redux";
import {forwardRef} from "react";
import {resetMessageApp} from "../store/slices/app/appSlice"

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarComponent = (props) => {
    const dispatch = useDispatch();
    const {messageApp} = useSelector((state) => state.app);

    // const [state, setState] = useState({
    //     // open: error.show,
    //     vertical: 'bottom',
    //     horizontal: 'right',
    // });
    const {vertical, horizontal} = {
        vertical: 'bottom',
        horizontal: 'right',
    };

    // const handleClick = (newState) => () => {
    //     setState({ ...newState, open: true });
    // };

    const handleClose = () => {
        dispatch(resetMessageApp());
    };

    return (
        <Stack spacing={2} sx={{width: '100%'}}>
            <Snackbar open={messageApp.show} onClose={handleClose} anchorOrigin={{vertical, horizontal}}>
                <Alert onClose={handleClose} severity={messageApp.type}
                       style={{fontSize: 20}}>
                    {messageApp.message}
                    {/*<CloseIcon />*/}
                </Alert>
            </Snackbar>
        </Stack>
    );
};

export default SnackbarComponent;
