import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        isLoading: false,
        currentPage: 'Clientes',
        messageApp: {
            show: false,
            // title: "",
            message: "Mensaje automático",
            type: "info"
        }
    },
    reducers: {
        setLoadingApp: (state, action) => {
            state.isLoading = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setMessageApp: (state, action) => {
            state.messageApp = { ...action.payload, show: true };
        },
        resetMessageApp: (state) => {
            //console.log("REINICIANDO ERROR")
            state.messageApp = {
                show: false,
                // title: "",
                message: "",
                type: "info"
            }
        }
    }
});

export const {
    setLoadingApp,
    setMessageApp,
    setCurrentPage,
    resetMessageApp
} = appSlice.actions;