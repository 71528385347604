import { createSlice } from "@reduxjs/toolkit";

export const tempSlice = createSlice({
  name: "temp",
  initialState: {
    locations: [],
    comments: [],
    users: [],
  },
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload.data;
    },
    setComments: (state, action) => {
      state.comments = action.payload.data;
    },
    setUsers: (state, action) => {
      state.users = action.payload.data;
    },
    // setPublicUser: (state, action) => {
    //   state.isLogged = action.payload.isLogged;
    // },
  },
});

export const { setLocations, setComments, setUsers } = tempSlice.actions;
