import React, {useEffect} from "react";
import {
    Paper,
    Box,
    Grid,
    TextField,
    CssBaseline,
} from "@mui/material";
import Image from "mui-image";
import {LoadingButton} from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Copyright from "../../globalComponents/Copyright";

import {logIn} from "../../store/slices/user";
import {setLoadingApp} from "../../store/slices/app";
import {useDispatch, useSelector} from "react-redux";
import SnackbarComponent from "../../globalComponents/SnackbarComponent";

import logo_main from "../../assets/image/logo_main.png";
import login_background from "../../assets/image/login_background.jpg";
import {colors} from "../../assets/css/colors";

const defaultTheme = createTheme();

const Login = () => {
    const {isLoading} = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const credentials = {
            username: formData.get("username"),
            password: formData.get("password"),
        };

        dispatch(logIn(credentials));
    };

    const init = () => {
        dispatch(setLoadingApp(false));
    }

    useEffect(() => {
        init()


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const handlePublicFunctions = () => {
    //     dispatch(logInPublic({ isLogged: false, isPublicUser: true }));
    // }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{height: "100vh"}}>
                <CssBaseline/>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    component={Paper}
                    elevation={6}
                    square
                    paddingTop={5}
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Image
                            src={logo_main}
                            duration={0}
                            height={250}
                            width={250}
                            fit={"contain"}
                            style={{marginBottom: 100, backgroundColor: ""}}
                        />
                        <Box component="form" onSubmit={handleSubmit} sx={{mt: 0}}>
                            <SnackbarComponent/>
                            <TextField
                                disabled={isLoading}
                                margin="normal"
                                required
                                fullWidth
                                name="username"
                                label="Email"
                                type="email"
                                id="username"
                                autoComplete="current-username"
                            />
                            <TextField
                                disabled={isLoading}
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                // color="green"
                            />
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    backgroundColor: colors.primary,
                                    "&:hover": {
                                        backgroundColor: colors.primary_dark,
                                    },
                                }}
                                loading={isLoading}
                                loadingPosition="end"
                                endIcon={<SendIcon/>}
                                // color="green"
                                // disabled={!canSubmit}
                            >
                                Iniciar Sesión
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Copyright style={{marginTop: 200}}/>
                </Grid>
                <Grid
                    item
                    xs={false}
                    sm={6}
                    md={8}
                    sx={{
                        backgroundImage: `url(${login_background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
            </Grid>
        </ThemeProvider>
    );
};

export default Login;
