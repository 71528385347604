import { createSlice } from "@reduxjs/toolkit";
import roles from "../../../helpers/roles";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    loginUser: {},
    loginUserToken: "",
    isLogged: false,
  },
  reducers: {
    setLoginUser: (state, action) => {
      state.loginUser = { ...action.payload.user.data, role: roles.admin };
      state.loginUserToken = action.payload.user.token;
      state.isLogged = action.payload.isLogged;
    },
    setPublicUser: (state, action) => {
      state.isLogged = action.payload.isLogged;
    },
  },
});

export const { setLoginUser, setPublicUser } = userSlice.actions;
