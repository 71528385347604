import apiManager from "../../../helpers/apiManager";
import { setLocations, setComments, setUsers } from "./tempSlice";
import { setLoadingApp, setMessageApp } from "../app/appSlice";
import { genConfigHeader } from "../../../helpers/apiManager";

export const getAllComments = (formData = null) => {
  return async (dispatch, getState) => {
    try {
      // console.log(getState());

      dispatch(setLoadingApp(true));

      const config = await genConfigHeader(getState());
      const response = await apiManager.get("/comment/getAllComments", config);

      if (!response.data.success && response.status !== 401) {
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "error",
          })
        );
        // dispatch(setCurrentPage("Dashboard"));
      } else {
        // console.log(response.data);
        dispatch(setComments({ data: response.data.data }));
        // console.log(response.data);
        // dispatch(setLoginUser({ user: response.data, isLogged: true }));
      }
    } catch (error) {
      console.error(error);
      await dispatch(
        setMessageApp({
          message: "Ocurrió un error inesperado, intente nuevamente.",
          type: "error",
        })
      );
    } finally {
      // console.log("finally me");
      dispatch(setLoadingApp(false));
    }
  };
};

export const updateComment = (formData = null) => {
  return async (dispatch, getState) => {
    try {
      // console.log(getState());

      dispatch(setLoadingApp(true));

      const config = await genConfigHeader(getState());
      const response = await apiManager.patch(
        "/comment/updateComment",
        formData,
        config
      );

      // await new Promise((resolve) => setTimeout(resolve, 2000));

      // console.log(response);
      if (!response.data.success && response.status !== 401)
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "error",
          })
        );
      else
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "success",
          })
        );
      return response.data;
    } catch (error) {
      console.error(error);
      await dispatch(
        setMessageApp({
          message: "Ocurrió un error inesperado, intente nuevamente.",
          type: "error",
        })
      );
    } finally {
      // console.log("finally me");
      dispatch(setLoadingApp(false));
    }
  };
};

export const getAllLocations = (formData = null) => {
  return async (dispatch, getState) => {
    try {
      // console.log(getState());

      dispatch(setLoadingApp(true));

      const config = await genConfigHeader(getState());
      const response = await apiManager.get(
        "/location/allLocations/all",
        config
      );

      // console.log(response);

      if (!response.data.success && response.status !== 401) {
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "error",
          })
        );
        // dispatch(setCurrentPage("Dashboard"));
      } else {
        // console.log(response.data);
        dispatch(setLocations({ data: response.data.data }));
        // console.log(response.data);
        // dispatch(setLoginUser({ user: response.data, isLogged: true }));
      }
    } catch (error) {
      console.error(error);
      await dispatch(
        setMessageApp({
          message: "Ocurrió un error inesperado, intente nuevamente.",
          type: "error",
        })
      );
    } finally {
      // console.log("finally me");
      dispatch(setLoadingApp(false));
    }
  };
};

export const updateLocation = (formData = null) => {
  return async (dispatch, getState) => {
    try {
      // console.log(getState());

      dispatch(setLoadingApp(true));

      const config = await genConfigHeader(getState());
      const response = await apiManager.patch(
        "/location/updateLocation",
        formData,
        config
      );

      // await new Promise((resolve) => setTimeout(resolve, 2000));

      // console.log(response);
      if (!response.data.success && response.status !== 401)
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "error",
          })
        );
      else
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "success",
          })
        );
      return response.data;
    } catch (error) {
      console.error(error);
      await dispatch(
        setMessageApp({
          message: "Ocurrió un error inesperado, intente nuevamente.",
          type: "error",
        })
      );
    } finally {
      // console.log("finally me");
      dispatch(setLoadingApp(false));
    }
  };
};

export const getAllUsers = (formData = null) => {
  return async (dispatch, getState) => {
    try {
      // console.log(getState());

      dispatch(setLoadingApp(true));

      const config = await genConfigHeader(getState());
      const response = await apiManager.get("/user/getAllUsers", config);

      // console.log(response);

      if (!response.data.success && response.status !== 401) {
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "error",
          })
        );
        // dispatch(setCurrentPage("Dashboard"));
      } else {
        // console.log(response.data);
        dispatch(setUsers({ data: response.data.data }));
        // console.log(response.data);
        // dispatch(setLoginUser({ user: response.data, isLogged: true }));
      }
    } catch (error) {
      console.error(error);
      await dispatch(
        setMessageApp({
          message: "Ocurrió un error inesperado, intente nuevamente.",
          type: "error",
        })
      );
    } finally {
      // console.log("finally me");
      dispatch(setLoadingApp(false));
    }
  };
};

export const updateUserStatus = (formData = null) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingApp(true));

      const config = await genConfigHeader(getState());
      const response = await apiManager.patch(
        "/user/updateStatus",
        formData,
        config
      );

      // console.log(response);
      if (!response.data.success && response.status !== 401)
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "error",
          })
        );
      else
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "success",
          })
        );
      return response.data;
    } catch (error) {
      console.error(error);
      await dispatch(
        setMessageApp({
          message: "Ocurrió un error inesperado, intente nuevamente.",
          type: "error",
        })
      );
    } finally {
      // console.log("finally me");
      dispatch(setLoadingApp(false));
    }
  };
};

export const validateToken = (formData = null) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingApp(true));

      const response = await apiManager.post("/auth/validateToken", formData);

      if (!response.data.success && response.status !== 401)
        await dispatch(
          setMessageApp({
            message: response.data.message,
            type: "error",
          })
        );
      return response.data;
    } catch (error) {
      console.error(error);
      await dispatch(
        setMessageApp({
          message: "Ocurrió un error inesperado, intente nuevamente.",
          type: "error",
        })
      );
    } finally {
      // console.log("finally me");
      dispatch(setLoadingApp(false));
    }
  };
};

export const resetPassword = (formData = null) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingApp(true));

      const response = await apiManager.patch("/auth/updatePasswordReset", formData);

      if (!response.data.success && response.status !== 401)
        await dispatch(
            setMessageApp({
              message: response.data.message,
              type: "error",
            })
        );
      return response.data;
    } catch (error) {
      console.error(error);
      await dispatch(
          setMessageApp({
            message: "Ocurrió un error inesperado, intente nuevamente.",
            type: "error",
          })
      );
    } finally {
      // console.log("finally me");
      dispatch(setLoadingApp(false));
    }
  };
};
