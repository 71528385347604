import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Grid,
  TextField,
  CssBaseline,
  CircularProgress,
  Typography,
} from "@mui/material";
import Image from "mui-image";
import { LoadingButton } from "@mui/lab";
// import SendIcon from "@mui/icons-material/Send";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import SnackbarComponent from "../../globalComponents/SnackbarComponent";

import logo_main from "../../assets/image/logo_main.png";
import login_background from "../../assets/image/login_background.jpg";
import { colors } from "../../assets/css/colors";
import { useParams } from "react-router-dom";

import { validateToken, resetPassword } from "../../store/slices/temp";
import { setMessageApp } from "../../store/slices/app";
import { logOut } from "../../store/slices/user";

const defaultTheme = createTheme();

export const ResetPwd = () => {
  const { isLogged } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  let { token } = useParams();

  const [finishProcess, setFinishProcess] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [responseValidate, setResponseValidate] = useState({ success: false });

  const init = async () => {
    // console.log("EFFECT", token);
    if (isLogged) await dispatch(logOut());
    const response = await dispatch(validateToken({ token: token + "" }));
    // console.log("RESPONSE", response);
    setInitialLoading(false);
    setResponseValidate(response);
  };

  useEffect(() => {
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    if (formData.get("password") !== formData.get("password-repeat")) {
      dispatch(
        setMessageApp({
          message: "Contraseñas deben ser iguales",
          type: "error",
        })
      );
      return;
    }

    const data = {
      password: formData.get("password"),
      token: token,
    };

    // setFinishProcess(true);
    // console.log(data);

    const response = await dispatch(resetPassword(data));
    if (response.success) setFinishProcess(true);

    // console.log("SUBMIT RESPONSE", response);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        // sx={{ height: "100vh", justifyContent: "center" }}
        sx={{
          height: "100vh",
          justifyContent: "center",
          backgroundImage: `url(${login_background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            my: 8,
            mx: 4,
            padding: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 15,
            width: "100%",
            maxWidth: 700,
          }}
          component={Paper}
          elevation={6}
        >
          <Image
            src={logo_main}
            duration={0}
            height={250}
            width={250}
            fit={"contain"}
            style={{ marginBottom: 0, backgroundColor: "" }}
          />
          {initialLoading && (
            <Box sx={{ mt: 20 }}>
              <CircularProgress style={{ color: colors.primary }} />
            </Box>
          )}

          {!initialLoading && finishProcess && (
            <Box sx={{ mt: 20 }}>
              <Typography
                variant="h5"
                color="text.secondary"
                align="center"
                style={{
                  position: "relative",
                  color: "black",
                }}
              >
                ¡Nueva contraseña establecida!
              </Typography>
            </Box>
          )}

          {!initialLoading && !responseValidate.success && !finishProcess && (
            <Box sx={{ mt: 5 }}>
              <Typography
                variant="h5"
                color="text.secondary"
                align="center"
                style={{
                  position: "relative",
                  color: "black",
                  marginTop: 50,
                }}
              >
                {responseValidate.message}
              </Typography>
            </Box>
          )}

          {!initialLoading && responseValidate.success && !finishProcess && (
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 5 }}>
              <SnackbarComponent />
              <TextField
                // disabled={isLoading}
                inputProps={{ minLength: 5 }}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Nueva contraseña"
                type="password"
                id="password"
              />
              <TextField
                // disabled={isLoading}
                inputProps={{ minLength: 5 }}
                margin="normal"
                required
                fullWidth
                name="password-repeat"
                label="Repite nueva contraseña"
                type="password"
                id="password-repeat"
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 10,
                  mb: 2,
                  backgroundColor: colors.primary,
                  "&:hover": {
                    backgroundColor: colors.primary_dark,
                  },
                }}
                // loading={isLoading}
                loadingPosition="end"
                endIcon={<LockPersonIcon />}
              >
                Establecer contraseña
              </LoadingButton>
            </Box>
          )}
        </Box>
      </Grid>
    </ThemeProvider>
  );
};

export default ResetPwd;
